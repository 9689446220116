import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { onAuthStateChanged, User } from 'firebase/auth'
import { firebaseAuth } from '@/config/firebaseClientConfig'
import { identify } from '@fullstory/browser'
import usePostUpsertUser from '@/src/common/hooks/usePostUpsertUser'

type AuthContextType = {
    user: User | null
    userLoaded: boolean
    refreshUser: () => Promise<void>
    error: string | null
    lastVerificationCheck: number
    isBackendUserCreated: boolean
    setIsBackendUserCreated: (isCreated: boolean) => void
}

const AuthContext = createContext<AuthContextType>({
    user: null,
    userLoaded: false,
    refreshUser: async () => {},
    error: null,
    lastVerificationCheck: 0,
    isBackendUserCreated: false,
    setIsBackendUserCreated: () => {},
})

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [user, setUser] = useState<User | null>(null)
    const [userLoaded, setUserLoaded] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [lastVerificationCheck, setLastVerificationCheck] =
        useState<number>(0)
    const [isBackendUserCreated, setIsBackendUserCreated] =
        useState<boolean>(false)

    const { mutate: upsertUser } = usePostUpsertUser()

    const refreshUser = async () => {
        console.log('refreshUser')
        if (firebaseAuth.currentUser) {
            try {
                await firebaseAuth.currentUser.reload()
                setUser(firebaseAuth.currentUser)
                setLastVerificationCheck(Date.now())
                setError(null)
                setIsBackendUserCreated(true)
            } catch (err) {
                const message =
                    err instanceof Error
                        ? err.message
                        : 'Failed to refresh user data'
                setError(message)
                console.error('Error refreshing user:', err)
            }
        }
    }

    const value = useMemo(
        () => ({
            user,
            userLoaded,
            refreshUser,
            error,
            lastVerificationCheck,
            isBackendUserCreated,
            setIsBackendUserCreated,
        }),
        [user, userLoaded, error, lastVerificationCheck, isBackendUserCreated]
    )

    const handleWindowFocus = () => {
        refreshUser().catch((err) => {
            console.error('Error refreshing user on window focus:', err)
        })
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(
            firebaseAuth,
            (user) => {
                setUser(user)
                setUserLoaded(true)
                setError(null)

                if (user && typeof window !== 'undefined') {
                    console.log(
                        'Creating/updating user in backend from AuthProvider'
                    )
                    upsertUser(null, {
                        onSuccess: () => {
                            console.log(
                                'Backend user created successfully from AuthProvider'
                            )
                            setIsBackendUserCreated(true)
                        },
                        onError: (err) => {
                            console.error(
                                'Error creating backend user from AuthProvider:',
                                err
                            )
                            setIsBackendUserCreated(false)
                            setError('Failed to create user record')
                        },
                    })

                    try {
                        identify(user.uid, {
                            displayName: user.displayName || 'Unknown',
                            email: user?.email || '',
                            phone: user?.phoneNumber || '',
                        })
                    } catch (e) {
                        console.error('FullStory error:', e)
                    }
                } else {
                    setIsBackendUserCreated(false)
                }
            },
            (err) => {
                console.error('Firebase auth state error:', err)
                setError(err.message)
                setUserLoaded(true)
            }
        )

        window.addEventListener('focus', handleWindowFocus)

        return () => {
            unsubscribe()
            window.removeEventListener('focus', handleWindowFocus)
        }
    }, [upsertUser])

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useUser = () => {
    const {
        user,
        refreshUser,
        userLoaded,
        error,
        lastVerificationCheck,
        isBackendUserCreated,
        setIsBackendUserCreated,
    } = useContext(AuthContext)

    return {
        user,
        refreshUser,
        isLoaded: userLoaded,
        isLoggedIn: !!user,
        isVerified: isUserVerified(user),
        authProviders: getUserAuthProviders(user),
        userLoginMethod: getUserLoginMethod(user),
        error,
        lastVerificationCheck,
        isBackendUserCreated,
        setIsBackendUserCreated,
        isFullyAuthenticated:
            !!user && isUserVerified(user) && isBackendUserCreated,
    }
}

function isUserVerified(user: User | null): boolean {
    if (!user) return false
    if (user.emailVerified) return true
    if (user.phoneNumber) return true

    const socialProviders = [
        'facebook.com',
        'google.com',
        'apple.com',
        'twitter.com',
    ]
    if (
        user.providerData?.some(
            (provider) =>
                provider && socialProviders.includes(provider.providerId)
        )
    ) {
        return true
    }

    return false
}

function getUserAuthProviders(user: User | null): string[] {
    if (!user) return []

    const providers =
        user.providerData?.map((provider) => provider.providerId) || []

    if (user.email && !providers.includes('password')) {
        providers.push('password')
    }

    if (user.phoneNumber && !providers.includes('phone')) {
        providers.push('phone')
    }

    // Remove duplicates
    return [...new Set(providers)]
}

function getUserLoginMethod(user: User | null): string {
    if (!user) return ''

    // Return the primary identifier - either email or phone
    if (user.email) return user.email
    if (user.phoneNumber) return user.phoneNumber

    // If no direct identifiers, check provider data
    const firstProvider = user.providerData?.[0]
    if (firstProvider) {
        // Return provider type and ID
        return `${firstProvider.providerId}:${firstProvider.uid}`
    }

    return user.uid || ''
}
