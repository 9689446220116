import { useEffect, useState } from 'react'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { IBanners } from '@/src/common/components/elements/Layout/Banners/types'
import BannerPopover from '@/src/common/components/elements/Layout/Banners/BannerPopover'
import {
    BannerButton,
    BannerContent,
    BannersWrapper,
    BannerTextSpan,
    BookEnd,
    ButtonsContainer,
    ColorfulContainer,
    StyledFontAwesomeIcon,
    TextAndIconWrapper,
} from '@/src/common/components/elements/Layout/Banners/StyledComponents'
import { useCookies } from 'react-cookie'
import { PROMO_CODE } from '@/config/cookieNames'
import { useRouter } from 'next/router'

const Banner = ({ banners }: IBanners) => {
    const router = useRouter()
    const [, setCookie] = useCookies([PROMO_CODE])
    const totalBanners: number = banners?.length ?? 0
    const hasMultipleBanners: boolean = totalBanners > 1
    const [activeBannerIndex, setActiveBannerIndex] = useState(0)
    const [canAutoScroll, setCanAutoScroll] = useState<boolean>(true)

    useEffect(() => {
        let intervalId: any

        if (canAutoScroll) {
            intervalId = setInterval(() => {
                setActiveBannerIndex(
                    (prevIndex) => (prevIndex + 1) % totalBanners
                )
            }, 6000)
        }

        return () => clearInterval(intervalId)
    }, [canAutoScroll, totalBanners])

    const setSlide = ({ moveToNextStep }: { moveToNextStep: boolean }) => {
        setCanAutoScroll(false)

        // determine which step to move to
        const newIndex = moveToNextStep
            ? (activeBannerIndex + 1) % totalBanners
            : (activeBannerIndex - 1 + totalBanners) % totalBanners
        setActiveBannerIndex(newIndex)

        setTimeout(() => {
            setCanAutoScroll(true)
        }, 500)
    }

    return (
        <ColorfulContainer>
            <ButtonsContainer>
                {hasMultipleBanners && (
                    <StyledFontAwesomeIcon
                        icon={faChevronLeft}
                        onClick={() => setSlide({ moveToNextStep: false })}
                        style={{ paddingRight: 10 }}
                    />
                )}
                <BannersWrapper>
                    {(banners ?? []).map((banner, index) => {
                        const {
                            buttonText,
                            buttonUrl,
                            bannerText,
                            promoTerms,
                            icon,
                            promoCode,
                        } = banner

                        return (
                            <BannerContent
                                key={banner.bannerId}
                                id={banner.bannerId}
                                className={
                                    index === activeBannerIndex ? 'active' : ''
                                }
                                style={{
                                    transform: `translateX(-${
                                        activeBannerIndex * 100
                                    }%)`,
                                }}
                            >
                                <TextAndIconWrapper
                                    onClick={() => {
                                        promoCode &&
                                            setCookie(PROMO_CODE, promoCode, {
                                                path: '/',
                                                domain: window.location
                                                    .hostname,
                                            })
                                        buttonUrl && router.push(buttonUrl)
                                    }}
                                >
                                    {icon && (
                                        <BookEnd height={16} width={16}>
                                            <image
                                                xlinkHref={icon}
                                                width={'100%'}
                                                height={'100%'}
                                            />
                                        </BookEnd>
                                    )}
                                    <BannerTextSpan
                                        dangerouslySetInnerHTML={{
                                            __html: bannerText,
                                        }}
                                    />
                                    {icon && (
                                        <BookEnd height={16} width={16}>
                                            <image
                                                xlinkHref={icon}
                                                width={'100%'}
                                                height={'100%'}
                                            />
                                        </BookEnd>
                                    )}
                                </TextAndIconWrapper>
                                {buttonText && (
                                    <BannerButton
                                        label={buttonText ?? ''}
                                        url={buttonUrl ?? ''}
                                        variant={'primaryOutline'}
                                        textTransform={'uppercase'}
                                        size={'sm'}
                                        showArrow={false}
                                    />
                                )}
                                {promoTerms && (
                                    <BannerPopover promoTerms={promoTerms} />
                                )}
                            </BannerContent>
                        )
                    })}
                </BannersWrapper>
                {hasMultipleBanners && (
                    <StyledFontAwesomeIcon
                        icon={faChevronRight}
                        onClick={() => setSlide({ moveToNextStep: true })}
                        style={{ paddingLeft: 10 }}
                    />
                )}
            </ButtonsContainer>
        </ColorfulContainer>
    )
}

export default Banner
