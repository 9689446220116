import * as React from 'react'
import 'react-modern-drawer/dist/index.css'
import isEmpty from 'lodash/isEmpty'
import Banner from '@/src/common/components/elements/Layout/Banners/Banner'

export interface IPromoBanner {
    promoBannerHtml: string
    isCorporate: boolean
    bannerData: any
}

const CorpPromoBanner: React.FC<IPromoBanner> = ({
    promoBannerHtml,
    bannerData,
}) => {
    return (
        <>
            {!isEmpty(promoBannerHtml) && (
                <div
                    dangerouslySetInnerHTML={{
                        __html: promoBannerHtml,
                    }}
                />
            )}
            {bannerData?.length > 0 && <Banner banners={bannerData} />}
        </>
    )
}

export default CorpPromoBanner
